.player-body {
  width: calc(100% - 4px);
  bottom: 0px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.player {
  // background: rgba(255, 255, 255, 0.9);
  background: #fff;
  border: 1px solid #fcfdff;
  // box-shadow: 0px 40px 64px rgba(23, 29, 51, 0.6);
  backdrop-filter: blur(16px);
  // border-radius: 20px;
  // width: 80%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  .icon-general-play {
    font-size: 20px;
    color: #8993b0;
    padding: 14px 15px;
    border: 1px solid #e6e1e1;
    margin: 0 15px;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(180deg, #f1f6fd -1.78%, #f3f8ff 101.77%);
    box-shadow: 0px 5px 5px rgba(19, 32, 80, 0.1);
    &:hover {
      background: linear-gradient(180deg, #f1f6fd -1.78%, #dae1ea 101.77%);
    }
  }
  .icon-nexts,
  .icon-prevs {
    font-size: 12px;
    color: #8993b0;
    padding: 14px 15px;
    border: 1px solid #e6e1e1;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(180deg, #f1f6fd -1.78%, #f3f8ff 101.77%);
    box-shadow: 0px 5px 5px rgba(19, 32, 80, 0.1);
    &:hover {
      background: linear-gradient(180deg, #f1f6fd -1.78%, #dae1ea 101.77%);
    }
  }
  // justify-content: space-between;
  .title-player {
    color: #4a4a4a;
    padding: 12px 0px 12px 10px;
    width: 77%;
    .name {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif !important;
      font-weight: 600;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #1d2932 !important;
      flex-shrink: 0;
      white-space: nowrap;
      overflow: auto;
      overflow-y: hidden;
    }
    .texts {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .text,
    .texts {
      font-family: Sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      //   margin-bottom: 8px;
      display: flex;
      align-items: center;
      color: #717eae;

      .province {
        padding: 4px 10px;
        margin: 8px 0px;
        background: #007bff;
        color: #fff;
        border-radius: 4px;
      }
      .items {
        max-width: 70%;
        overflow: auto;
        display: flex;
        span {
          flex-shrink: 0;
        }
      }
    }
  }
  .player-left {
    width: 40%;
  }
  .Control-Button-Group {
    align-items: center;
    color: #4a4a4a;

    .icon-prev,
    .icon-pause,
    .icon-play,
    .icon-next {
      padding: 16px;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      border-radius: 50%;
      cursor: pointer;
      margin-right: 24px;
    }
  }
  .audio {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    display: none !important;
    .rhap_container {
      background: transparent !important;
      box-shadow: none !important;
      padding: 0px 30px 0px 0px;
      .rhap_skip-button {
        background-color: rgba(177, 212, 224, 0.7);
        backdrop-filter: blur(5px);
        border-radius: 50%;
        color: #22a0db;
      }
      .rhap_play-pause-button {
        background: rgba(0, 123, 255, 0.1);
        backdrop-filter: blur(5px);
        border-radius: 50%;
        backdrop-filter: blur(5px);
        color: #22a0db;
      }
    }
    .rhap_current-time,
    .rhap_time,
    .rhap_button-clear {
      color: #22a0db !important;
    }
    .rhap_total-time {
      display: none !important;
    }
    .rhap_additional-controls {
      display: none !important;
    }
    .rhap_main-controls {
      position: relative !important;
    }
    .rhap_progress-section {
      position: absolute !important;
      z-index: 9999;
      left: 25%;
      width: 50%;
      top: 40%;
    }
    .rhap_controls-section {
      margin-top: 0 !important;
    }

    audio::-webkit-media-controls-panel {
      background: rgba(255, 255, 255, 0.9) !important;
      // opacity: 0.7 !important;
      color: #22a0db;
      backdrop-filter: blur(16px);
    }
    audio::-webkit-media-controls-time-remaining-display {
      display: none !important;
    }

    audio::-webkit-media-controls-play-button {
      background-color: #c0e3ee;
      border-radius: 50% !important;
      margin-left: 40px !important;
      margin-right: 60px !important;
      padding: 23px !important;
      color: #22a0db !important;
    }

    audio::-webkit-media-controls-play-button:hover {
      background-color: rgba(177, 212, 224, 0.7) !important;
      color: #22a0db !important;
    }
    audio::-webkit-media-controls-current-time-display {
      color: #007bff !important;
    }
    audio::-webkit-media-controls-timeline {
      color: #007bff !important;
    }
    audio::-webkit-media-controls-mute-button {
      background-color: #b1d4e0 !important;
      border-radius: 50% !important;
      color: #007bff !important;
    }
    audio::-webkit-media-controls-volume-slider {
      background-color: #b1d4e0 !important;
      display: block !important;
      border-radius: 25px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
    .icon-next {
      padding: 13px 14px;
      background-color: #c0e3ee;
      font-size: 14px;
      border-radius: 50%;
      left: 100px;
      color: #22a0db;
      position: absolute;
      &:hover {
        cursor: pointer;
        background-color: rgba(177, 212, 224, 0.7);
      }
    }
    .icon-prev {
      padding: 13px 14px;
      background-color: #c0e3ee;
      font-size: 14px;
      border-radius: 50%;
      left: 5px;
      color: #22a0db;
      position: absolute;
      &:hover {
        cursor: pointer;
        background-color: rgba(177, 212, 224, 0.7);
      }
    }
  }
  .audio-player {
    width: 60%;
  }
}
@media screen and (max-width: 1281px) {
  .player {
    // width: 85%;
  }
}
@media screen and (max-width: 1000px) {
  .rhap_progress-section {
    display: none !important;
  }
}
@media screen and (max-width: 845px) {
  .player-body {
    width: 100%;
  }
  .player {
    .player-left {
      width: 50%;
      .image-list {
        padding-left: 8px !important;
        width: 76px;
      }
    }
    .audio-player {
      width: 50%;
      display: flex;
      justify-content: center;
      // margin-right: -20px;
      .section-volume {
        display: none !important;
      }
      .audio {
        .win {
          // opacity: 0;
          display: none !important;
        }
        .icon-next,
        .icon-prev {
          display: none !important;
        }
        .player-win,
        .pause-win {
          display: block !important;
        }
        .rhap_stacked {
          // margin-left: 150px;
        }
        audio::-webkit-media-controls-panel {
          background-color: #fff !important;
          // opacity: 0.7 !important;
          color: #22a0db;
        }
        audio::-webkit-media-controls-time-remaining-display {
          display: none !important;
        }

        audio::-webkit-media-controls-play-button {
          background-color: #c0e3ee;
          // border-radius: 50% !important;
          margin-left: 0px !important;
          margin-right: 0px !important;
          padding: 0px !important;
          color: #22a0db !important;
        }

        audio::-webkit-media-controls-play-button:hover {
          display: none !important;
        }
        audio::-webkit-media-controls-current-time-display {
          // display: none !important;
        }
        audio::-webkit-media-controls-timeline {
          // display: none !important;
        }
        audio::-webkit-media-controls-mute-button {
          display: none !important;
        }
        audio::-webkit-media-controls-volume-slider {
          display: none !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        audio::-webkit-media-controls-toggle-closed-captions-button {
          display: none !important;
        }
        audio::-webkit-media-controls-mute-button {
          display: none !important;
        }
        audio::-webkit-media-controls-volume-slider-container {
          display: none !important;
        }
        audio::-webkit-media-controls-seek-back-button {
          display: none !important;
        }
        audio::-webkit-media-controls-toggle-closed-captions-button {
          display: none !important;
        }
      }
    }
    .audio {
      width: 100%;
    }
  }
  audio::-webkit-media-controls-toggle-closed-captions-button {
    display: none !important;
  }
  .iphone-audio {
    opacity: 0 !important;
  }
  .title-player {
    width: 72% !important;
    padding: 8px 15px !important;
    .name {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif !important;
      width: 100% !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 19px !important;
      color: #1d2932;
      margin-bottom: 0 !important;
    }
    .texts {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 15px !important;
      display: none !important;
    }
    .text-player {
      flex-direction: column;
    }
    .Path {
      // display: none !important;
    }
    .path-link {
      display: block !important;
    }
    .addresss {
      // display: none !important;
      width: auto !important;
    }
    .marker-address {
      display: block !important;
    }
    .Signal {
      // display: none !important;
      margin-left: 0 !important;
    }
    .text {
      margin-bottom: 1.5px;
      .province {
        margin: 2px 0px 2px 8px !important;
      }
    }
  }

  .rhap_volume-controls {
    display: none !important;
  }
  .rhap_progress-section {
    display: none !important;
  }
  .rhap_skip-button {
    display: none !important;
  }
  .rhap_controls-section {
    justify-content: center !important;
  }
}
@media screen and (max-width: 410px) {
  .audio-player {
    width: 20% !important;
    justify-content: end !important;
  }
  .player-left {
    width: 80% !important;
  }
  .icon-prevs,
  .icon-nexts {
    display: none !important;
  }
  .rhap_stacked {
    margin-left: 0px !important;
  }
}
