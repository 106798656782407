.dropdown {
  height: 100% !important;
  background: rgba(255, 255, 255, 0) !important;
  border: none !important;
  //   color: black;
  .btn {
    height: 100% !important;
    background: transparent !important;
    color: #4a4a4a;
    border-radius: 0 !important;
    width: 100%;
    padding: 0 32px;
    border-left: 1px solid #ccc;
    border-top: none;
    border-bottom: none;
    border-right: none;
    text-align: justify;
  }
  .dropdown-menu {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(5px) !important;
  }
}
.dropdown-menu {
  padding: 0 !important;
  .dropdown-item {
    &:hover {
      background: rgba(227, 240, 253, 0.9);
      border-bottom: 0.5px solid rgba(74, 74, 74, 0.1);
    }
  }
}
@media screen and (max-width: 845px) {
  .dropdown-toggle {
    width: 44px;
    padding: 0 !important;
    border: none !important;
  }
  .dropdown-menu {
    .dropdown-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #4a4a4a;
    }
  }
  .btn {
    padding: 0px;
  }
  .text-source {
    display: none !important ;
  }
}
