.change-list {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-style: normal;
  overflow-x: hidden;
  background-image: url("../../../../assets/image/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    margin-top: -56px;
    margin-right: 21px;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 24px;
    left: 36px;
    width: 3px;
    height: 10px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .back-group {
    height: 100vh;
  }
  .fa-map-marker {
    font-size: 17px;
  }
  .text-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .filter {
    top: 0px;
    right: 128px;
    height: 100%;
  }

  .list {
    height: 100vh;
    overflow: auto;
    padding-top: 30px;
    text-align: center;
    .text {
      color: #fff;
      margin-top: 18px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif !important;
      h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 25px;
      }
      span {
        color: #86e2ff;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .logo {
      height: 82px;
    }
    .search-change {
      margin-top: 24px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif !important;
    }
    .search-changes {
      padding: 7px 0;
      border: 1px solid #fcfdff;
      // background: #fff;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 40px 64px rgba(23, 29, 51, 0.6);
      // backdrop-filter: blur(16px);
      border-radius: 30px;
      align-items: center;
    }
    .search {
      width: 100%;
      height: 32px;
      // border: none;
      background: rgba(255, 255, 255, 0);

      backdrop-filter: blur(16px);
      margin: 0 30px;
      border: none !important;
      &:focus-visible {
        outline: none !important;
      }
      color: #007bff;
      &::placeholder {
        color: rgba(113, 126, 174, 0.6);
      }
    }
    .icon-search {
      background: linear-gradient(13.82deg, #446eff 8.59%, #03bdff 91.64%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      padding: 16px;
      // height: 100%;
      color: #fff;
      margin-right: 9px;
      cursor: pointer;
      min-width: max-content;
      &:hover {
        background: #2140f0;
      }
    }
    .group {
      width: 100% !important;
      .title-header {
        width: 88%;
        .name-header {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: start;
        }
        .link-header {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .group-list {
      // background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(15px);
      display: flex;
      justify-content: center;
      border-radius: 16px;
      margin: 0 auto;
      margin-top: 40px;
      // padding: 8px;
      // overflow: hidden;
    }
    .list-change {
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 16px;
      .header {
        border-radius: 12px 12px 0px 0px;
        display: flex;
        padding: 16px;
        text-align: center;
        background: radial-gradient(
          105.96% 105.96% at 55.37% -32.46%,
          #0ab8ff 0%,
          #269ef6 100%
        );
        color: #fff;
        .image-header {
          width: 100px;
          // height: 110px;
          background: #fff;
          border-radius: 12px;
          margin-right: 16px;
        }
        .title-header {
          .name-header {
            font-size: 18px;
            font-weight: 800;
            line-height: 21px;
            color: #fff;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif !important;
          }
          .text-header {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif !important;
          }
          .province {
            padding: 4px 10px;
            background: #fff;
            border-radius: 4px;
            min-width: max-content;
            color: #4a4a4a;
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
          }
        }
      }
      .body-change {
        border-radius: 16px;
      }
      .list-items {
        &:first-child {
          border-radius: 16px 16px 0px 0px !important;
        }
        &:last-child {
          border-radius: 0px 0px 16px 16px !important;
        }
      }
      // .list-content {
      //   // max-height: 10vh;
      //   overflow: hidden;
      //   border-radius: 0px 0px 12px 12px;
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
      }
      .image-list {
        width: 90px;
        // background: #fff;
        border-radius: 12px;
        margin-right: 16px;
      }
      .province {
        padding: 4px 10px;
        background: #1cade8;
        flex-shrink: 0;
        border-radius: 4px;
        width: max-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #ffffff;
      }
      .icon-load {
        right: -23px !important;
        .spinner-4 {
          width: 26px;
          --b: 8px; /* the border thickness */
          aspect-ratio: 1;
          border-radius: 50%;
          padding: 1px;
          background: conic-gradient(#0000 10%, #f8f7f8) content-box;
          -webkit-mask: repeating-conic-gradient(
              #0000 0deg,
              #000 1deg 20deg,
              #0000 21deg 36deg
            ),
            radial-gradient(
              farthest-side,
              #0000 calc(100% - var(--b) - 1px),
              #000 calc(100% - var(--b))
            );
          -webkit-mask-composite: destination-in;
          mask-composite: intersect;
          animation: s4 1s infinite steps(10);
        }
        @keyframes s4 {
          to {
            transform: rotate(1turn);
          }
        }
        body {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          grid-auto-rows: 130px;
          place-items: center;
        }
      }
      .list-items {
        background: #fff;
        border-bottom: 1px solid rgba(74, 74, 74, 0.2);
        backdrop-filter: blur(10px);
        cursor: pointer;
        padding: 12px;
        .icon-list {
          color: #717eae;
          display: flex;
          align-items: center;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
          .icon-play {
            margin-top: 12px;
            width: auto;
            transform: translateX(-50%) translateY(-50%);
          }

          .now.icon-play .bar {
            display: inline-block;
            position: relative;
            margin-right: 1px;
            width: 4px;
            height: 0.5px;
            overflow: hidden;
            background: linear-gradient(to bottom, #f3f3f2, #fff5f3);
            color: transparent;
            animation-name: pulse;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }
          .paused.icon-play .bar {
            animation-iteration-count: 1;
          }
          .paused.icon-play:after {
            content: "Paused (Lame)";
          }
          .n1 {
            animation-delay: 0.1s;
          }
          .n2 {
            animation-delay: 0.8s;
          }
          .n3 {
            animation-delay: 0.4s;
          }
          .n4 {
            animation-delay: 0.9s;
          }

          @keyframes pulse {
            0% {
              height: 1px;
              margin-top: 0;
            }
            10% {
              height: 20px;
              margin-top: -20px;
            }
            50% {
              height: 15px;
              margin-top: -15px;
            }
            60% {
              height: 22px;
              margin-top: -22px;
            }
            80% {
              height: 18px;
              margin-top: -18px;
            }
            100% {
              height: 1px;
              margin-top: 0;
            }
          }
        }
      }
      .title-header {
        width: 82%;
        .name-header {
          font-weight: 800;
          font-size: 18px;
          line-height: 21px;
          color: #1d2932;
        }
        .link-header {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6b7390;
        }
        .address {
          font-weight: 900;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #717eae;
        }
      }
    }
    .items1 {
      display: flex;
      align-items: center;
      margin-left: 24px;
    }
  }
}
@media screen and (max-width: 1281px) {
  .group-list {
    width: 85% !important;
  }
  .search-changes {
    width: 85% !important;
  }
}
@media screen and (max-width: 845px) {
  .list {
    padding-top: 0px !important;
    .logo {
      // display: none !important;
      height: 54px !important;
      width: 54px !important;
      margin-top: 10px;
    }
    .list-header {
      justify-content: start !important;
      margin-left: 3%;
    }
    .icon-load {
      display: block !important;
    }
    .text {
      .name-text {
        // display: none !important;
        font-size: 13px !important;
        line-height: 14px !important;
      }
      .text-ipfm {
        font-weight: 900 !important;
        font-size: 24px !important;
        line-height: 24px !important;
      }
    }
  }
  .search-change {
    margin-top: 10px !important;
    .search {
      padding-right: 15px;
      height: 30px !important;
      font-size: 14px !important;
    }
  }
  .group-list {
    // background: none !important;
    width: 94% !important;
    margin-top: 11px !important;
    margin-bottom: 100px !important;
    padding: 0px !important;
    // background: none !important;
  }
  .list-change {
    .title-header {
      width: 73% !important;
    }
  }
  .search-changes {
    width: 94% !important;
    justify-content: start !important;
    margin-left: 3% !important;
    padding: 3px 0 !important;
    .icon-search {
      display: none !important;
    }
    .search {
      padding: 0 !important;
      border-left: 0 !important;
      margin: 0 20px !important;
    }
    .filter {
      right: -51px !important;
      background: #007bff;
      border-radius: 50px;
      margin-right: 11px;
      .title-dropdown {
        display: none !important;
      }
      .icons-bar {
        display: block !important;
        padding: 11px;
        box-shadow: 50%;
      }
      .btn-primary {
        &::after {
          display: none !important;
        }
      }
    }
    // .search {
    //   border-radius: 16px !important;
    // }
  }
  .title-header {
    width: 67%;
    height: 57px;
    .name-header {
      font-size: 26px !important;
      line-height: 30px !important;
      display: flex;
      white-space: nowrap;
      overflow: auto;
    }
  }
  .header {
    padding: 10px !important;
    .text-header {
      border: none !important;
      padding-right: 0 !important;
      margin-left: 0 !important;
    }
    .image-header {
      height: 83px !important;
      width: 86px !important;
    }
    .location-header {
      justify-content: space-between;
      .text-header {
        max-width: 60%;
      }
      .location {
        max-width: 100%;
        overflow: auto;
        display: flex;
      }
      .type {
        max-width: 35%;
        .town {
          max-width: 100%;
          overflow: auto;
          display: flex;
        }
      }
    }
  }
  .list-content {
    .title-header {
      width: 72.5%;
    }
  }
  .filter {
    right: 113px !important;
  }
  .address {
    width: 100% !important;
    color: #717eae !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;

    .items-address {
      display: flex;
      max-width: 85%;
      overflow: auto;
    }
    .items {
      max-width: 50%;
      .province {
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14px !important;
        padding: 2px 4px !important;
      }
    }
    .items1 {
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
      margin-left: 0 !important;
    }
    .items1 {
      padding-left: 15px;
    }
    .items-province {
      display: flex;
      overflow: auto;
    }
  }
  .list-items {
    position: relative;
    padding: 8px !important;
    width: 100% !important;
    .group {
      width: 100% !important;
      .icon-play {
        display: block !important;
        right: -24px;
      }
      .icon-play {
        position: fixed;
        top: 9px;
        right: -26px;
        width: auto;
        transform: translateX(-50%) translateY(-50%);
      }
      .now.icon-play .bar {
        display: inline-block;
        position: relative;
        margin-right: 1px;
        width: 4px;
        height: 0.5px;
        overflow: hidden;
        background: linear-gradient(to bottom, #f3f3f2, #fff5f3);
        color: transparent;
        animation-name: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
      .paused.icon-play .bar {
        animation-iteration-count: 1;
      }
      .paused.icon-play:after {
        content: "Paused (Lame)";
      }
      .n1 {
        animation-delay: 0.1s;
      }
      .n2 {
        animation-delay: 0.8s;
      }
      .n3 {
        animation-delay: 0.4s;
      }
      .n4 {
        animation-delay: 0.9s;
      }

      @keyframes pulse {
        0% {
          height: 1px;
          margin-top: 0;
        }
        10% {
          height: 12px;
          margin-top: -12px;
        }
        50% {
          height: 7px;
          margin-top: -7px;
        }
        60% {
          height: 8px;
          margin-top: -8px;
        }
        80% {
          height: 10px;
          margin-top: -10px;
        }
        100% {
          height: 1px;
          margin-top: 0;
        }
      }
    }
    .icon-list {
      display: none !important;
    }
    .image-list {
      height: 100%;
      width: 54px !important;
    }
    .title-header {
      width: 73% !important;
      margin-top: 0px !important;
      .name-header {
        font-size: 18px !important;
        line-height: 18px !important;
        margin-bottom: 0px !important;
      }
      .link-header {
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 15px !important;
        margin-bottom: 2px !important;
      }
    }
  }
  &::-webkit-scrollbar {
    height: 0px;
    cursor: pointer;
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    cursor: pointer;
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 5px;
    cursor: pointer;
  }
}
@media screen and (max-width: 410px) {
  .search-changes {
    width: 94% !important;
    justify-content: start !important;
    margin-left: 3% !important;
    .icon-search {
      // padding-left: 16px !important;
      // span {
      //   display: none !important;
      // }
      display: none !important;
    }
    .filter {
      right: -51px !important;
      background: #007bff;
      border-radius: 50px;
      margin-right: 11px;
      .title-dropdown {
        display: none !important;
      }
      .icons-bar {
        display: block !important;
      }
      .btn-primary {
        &::after {
          display: none !important;
        }
      }
    }
    // .search {
    //   border-radius: 16px !important;
    // }
  }
}
