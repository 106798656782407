.dfm {
  padding: 4px 10px;
  background: #007bff;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}
.mp3 {
  padding: 4px 10px;
  background: #785bff;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}
.mic {
  padding: 4px 10px;
  background: #ff4ecd;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}
.offline {
  padding: 4px 10px;
  background: #bd2a2a;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
}
@media screen and (max-width: 845px) {
  .mic,
  .dfm,
  .mp3,
  .offline {
    padding: 2px 4px !important;
    font-size: 12px !important;
  }
}
